/* Min Width Sub-Menu */
.dropdown-menu{
	min-width: 14rem;
}


/* Menu Shrink */
body{
	
	.ovamenu_shrink_mobile,
	.ovamenu_shrink{
		-webkit-transition: padding-top .3 linear, padding-bottom .3s linear;
		transition: padding-top .3s linear, padding-bottom .3s linear;
		//position: static;
		//top: -100px;
		/* If mobile doesnt use Fixed => use bellow code */
		/*@media(max-width: 991.98px){
			position: relative;
			top: 0;
		}*/
		&.active_fixed{
		    position: fixed;
		    top: 0px;
		    width: 100%;
		    left: 0;
		    z-index: 999;
		    background: #fff;
		    box-shadow: 0px 1px 6px 0px #c5c5c5;
		    /* If mobile doesnt use Fixed => use bellow code */
		    /*@media(max-width: 991.98px){
				position: relative;
			}*/
			&.padding_tb{
				padding-top: 0 !important;
				padding-bottom: 0 !important;
			}
			&.bg_dark{
				background-color: #343434!important;
				box-shadow: 0px 1px 6px 0px #696969;
			}
			&.bg_white{
				background-color: #fff!important;	
			}
		}
	}
		
		
	&.admin-bar{
		.ovamenu_shrink_mobile.active_fixed,
		.ovamenu_shrink.active_fixed{
			top: 32px;
			@media( max-width: 782.98px ){
				top: 46px;
			}
			@media( max-width: 599.98px ){
				top: 0px;
			}
			&.bg_dark{
				background-color: #343434!important;
				box-shadow: 0px 1px 6px 0px #696969;
			}
			&.bg_white{
				background-color: #fff!important;	
			}
		}

	}
}





.ovatheme_header_default nav.navbar,
.ovatheme_header_default  nav.navbar{

	width: 100%;
	
	/* Logo */
	.navbar-brand{
		padding-top: 30px;
		padding-bottom: 30px;
		font-weight: bold;
    	text-transform: uppercase;
	}

	/* Menu Item */
	li{
		a{
			text-transform: uppercase;
			color: #343434;
			font-weight: 500;
			padding: 30px 15px;
			font-size: 14px;
			display: inline-block;	
		}
		&.menu-item-has-children{
			&>a{
				width: 100%;
				&:after{
					display: inline-block;
				    width: 0;
				    height: 0;
				    margin-left: .255em;
				    vertical-align: .255em;
				    content: "";
				    border-top: .3em solid;
				    border-right: .3em solid transparent;
				    border-bottom: 0;
				    border-left: .3em solid transparent;
				    float: right;
				    margin-top: 10px;
				}
			}
			.dropdown-toggle{
				display: none;
			}

			i.arrow_carrot-down{
				display: none;
			}
		}
	}

	

	/* Sub-menu */
	ul.dropdown-menu {
		li{
			&>a{
				padding: 7px 15px;
				display: inline-block;
				white-space: nowrap;
			}
		}
		&>.active{
			&>a{
				background-color: transparent;
				&:focus,
				&:hover{
					background-color: transparent;
				}

			}
		}
		
	}

	/* last-child level 0 */
	ul.nav>li:last-child>a{
		padding-right: 0;
	}

	/* Menu Level 3 */
	ul.nav ul.dropdown-menu ul.dropdown-menu{
		right: 100%;
		left: auto;
	    top: 0;
	}

	

	/* Show Sub-Menu when hover */
	@media(min-width: 992px){

		ul.nav li.dropdown:hover>ul{
			display: block;
			top: 100%;
			margin: 0;
		}
		ul.nav ul li.dropdown:hover>ul{
			top: 0;
		}
	}

	@media(max-width: 991.98px){

		button.navbar-toggler{
			&:focus{
				outline: none;
			}
		}
		ul.nav li > a{
			width: 100%;
			border-bottom: 1px solid #eee;
			padding-left: 0;
		}
		ul.nav ul.dropdown-menu{
			border: none;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
			margin-left: 10px;
			li{
				&.dropdown{
					.dropdown-toggle{
						display: none;
					}
				}
			}	

			ul.dropdown-menu{
				display: block;
				border: none;
			}
			
		}
		
		li{
			a{
				padding: 7px 0px;
				display: inline-block;
			}
			&.dropdown{
				&>a{
					&:after{
						display: none;
					}
				}
				.dropdown-toggle{
					display: inline-block;
					padding-left: 0px;
					position: absolute;
    				right: 10px;
    				top: 8px;
    				background: transparent;
    				border: none;
    				&:focus{
    					outline: none;
    				}
				}
			}
			&.active_sub{
				>ul.dropdown-menu{
					display: block;
				}
			}
		}
		.navbar-brand{
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

}


/* Megamenu Display */
.ovatheme_header_default .ova-mega-menu{
	width: 100%!important;
	margin-top: -15px;
}




